#user-registration-container {
	display: flex;
	flex-direction: column;
	gap: 1.5vh;
	align-items: center;
	font-family: "Open Sans", sans-serif;
}

.instructions {
	width: 350px;
	border: solid 1px black;
	padding-left: 5px;
	padding-right: 5px;
	margin-bottom: 0;
}
