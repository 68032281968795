.leaflet-container {
	height: 90vh;
}

#eumetsat-legend-table {
	border-collapse: collapse;
	border: 1px solid black;
	width: 5vw;
	height: 30vh;
	background-color: white;
}

td,
th {
	border: 1px solid black;
}

.color-width {
	width: 0.5vw;
}

.center-text {
	text-align: center;
}

.saws-marker {
	background-color: white;
	border-radius: 5px;
}
