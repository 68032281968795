#navbar-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 2vw;
	padding-right: 2vw;
	background-color: rgb(0, 91, 165);
	color: white;
	height: 5vh;
	padding-top: 1vh;
	padding-bottom: 1vh;
	font-family: "Open Sans", sans-serif;
}

#logo-heading-container {
	display: flex;
	gap: 0.5vw;
	align-items: center;
}

#up-logo {
	width: 3vw;
	min-width: 45px;
	height: 6vh;
	object-fit: cover;
}

#up-logo:hover {
	cursor: pointer;
}

#main-heading:hover {
	cursor: pointer;
}

#responsive-heading:hover {
	cursor: pointer;
}

#button-menu {
	display: flex;
	justify-content: space-between;
	gap: 1vw;
}

#drop-menu-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 1vw;
}

/* Screen width greater than or equal to 700px show the main heading */
@media (min-width: 820px) {
	#responsive-heading {
		display: none;
	}
}

/* Screen width less than or equal to 699px show the responsive heading */
@media (max-width: 819px) {
	#main-heading {
		display: none;
	}
}

/* Screen width less than or equal to 900px hide the Welcome/Username */
@media (max-width: 1100px) {
	h2 {
		display: none;
	}
}

/* Screen width less than or equal to 700px change menu to drop down */
@media (max-width: 820px) {
	#button-menu {
		display: none;
	}
}

/* Screen width greater than or equal to 701px change menu to drop down */
@media (min-width: 821px) {
	#drop-menu {
		display: none;
	}
}
