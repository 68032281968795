#form-container {
	border: 1px solid black;
	width: 350px;
	padding-bottom: 2vh;
	font-family: "Open Sans", sans-serif;
}

#form {
	display: flex;
	flex-direction: column;
	gap: 1.5vh;
	align-items: center;
}

#add-more-data-checkbox-container {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
}
